.sidebar {
  color: #828282 !important;
  height: 100vh;
  width: 250px;
}
.sidebar-closed {
  width: 100px;
  margin-top: 20px;
}

.top_section {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
  padding: 15px 10px;
  cursor: pointer;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}
.logo {
  margin-top: 8px;
}
.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0 15px;
}
.link {
  display: flex;
  align-items: center;
  color: #828282;
  gap: 10px;
  padding: 12px 10px;
  text-decoration: none;
  line-height: 1.7rem;
  font-size: 20px;
  font-weight: 600;
}
.activeLogo {
  line-height: 1.7rem;
  background: #219653;
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.link.active img {
  filter: brightness(0) invert(1);
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}
.main-container.navbar-collapsed .top_section {
  display: flex;
  justify-content: center;
  margin-left: 10px !important;
}
.main-container.navbar-collapsed .routes {
  margin-top: 21px !important;
}
.main-container.navbar-collapsed a.link {
  display: flex;
  justify-content: center;
  height: 51px;
}
