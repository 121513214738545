.common-section h2 {
  color: var(--font-color);
  font-size: 32px;
  font-weight: bold;
  text-align: left !important;
}
/* Add this style to your loginModule.css */

.addlocation_header {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #ffffff;
  width: 100%;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 31px;
  padding-bottom: 15px;
}
.addlocation_header h2 {
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addlocation_header img {
  border-radius: 50%;
}
.addlocation_header span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #bdbdbd;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  margin-right: 10px;
}
.addlocation_header span .fa {
  position: relative;
}
.addlocation_header span .fa::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 6px;
  background-color: #eb5757;
  border-radius: 50%;
}
.addlocation_header h2 {
  font-size: 26px;
}
.profile-dropdown-container {
  position: relative;
  cursor: pointer;
}

.profile-dropdown-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profile-dropdown {
  position: absolute;
  margin-top: 10px;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 270px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  padding: 10px;
}

.profile-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-dropdown li {
  padding: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.profile-dropdown li:last-child {
  color: red;
}
.profile-info {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
  padding: 18px 0 21px;
  position: relative;
}

.profile-info h3 {
  font-weight: bold;
  font-size: 18px;
}

.profile-info .user_email {
  font-size: 14px;
  color: #828282;
}

.profile-info img {
  width: 80px;
  height: 80px;
}
