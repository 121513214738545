.addlocation_section {
  margin: auto;
  position: absolute;
  top: 55%;
  left: 60%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.addlocation_section p {
  color: #828282;
}

.addlocation_section button {
  width: 145px;
  height: 40px;
  border: 1px dashed #219653;
  border-radius: 0px;
  color: #219653;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-left: 10px;
}

.addlocation_section .location_button {
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.add-location-main {
  width: 100%;
  background: #f9fcfe;
  padding: 0 20px 20px 20px;
}

.add-location-main .location-info-head {
  width: 100%;
  padding: 23px 30px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
}

.add-location-main .location-info-head h3 {
  font-size: 20px;
  font-weight: 600;
}

.add-location-main .location-all-details {
  width: 100%;
  padding: 29px 30px 0;
}

.add-location-main .location-all-details .site-location {
  width: 100%;
  display: flex;
}

.add-location-main .location-all-details .site-location .input-field {
  width: 100%;
  max-width: 344px;
  margin: 0 32px 0 0;
}

.add-location-main .location-all-details .site-location .input-field label {
  display: block;
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
}

.add-location-main .location-all-details .site-location .input-field input {
  width: 100%;
  min-height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 24px;
}

.add-location-main
  .location-all-details
  .site-location
  .input-field
  input::placeholder {
  color: #bdbdbd;
}

.add-location-main .location-all-details .location-discription {
  width: 100%;
  margin: 26px 0 31px;
}

.add-location-main .location-all-details .location-discription label {
  display: block;
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
}

.add-location-main .location-all-details .location-discription textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  min-height: 100px;
  padding: 16px 24px 0;
}

.input-textarea {
  width: 100% !important;
}

.input-textarea textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  min-height: 100px;
  padding: 16px 24px 0 !important;
}

.site-location_one {
  width: 100%;
  display: flex;
  gap: 20px;
  margin: 0 0 15px;
}

.add-location-main
  .location-all-details
  .location-discription
  textarea::placeholder {
  font-family: "Rubik", sans-serif;
  color: #e0e0e0;
}

.add-location-main .location-all-details .location-img {
  width: 100%;
  display: flex;
}

.add-location-main .location-all-details .location-img .active-location {
  margin: 0 40px 0 0;
  position: relative;
}

.add-location-main
  .location-all-details
  .location-img
  .active-location
  .delete-loaction {
  width: auto;
  position: absolute;
  bottom: 8px;
  right: 5px;
}

.add-location-main
  .location-all-details
  .location-img
  .active-location
  .delete-loaction
  span {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  color: #eb5757;
  cursor: pointer;
}

.add-location-main .location-all-details .location-img .upload-img {
  width: 100%;
  max-width: 147px;
  height: 147px;
  border: 2px dotted #bdbdbd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-location-main .location-all-details .location-img .upload-img input {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.add-location-main .location-all-details .location-img .upload-img .fa {
  color: #828282;
}

.location-save-btn {
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 20px 0 56px 0;
}

.amenities-address {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 15px;
}

.amenities-address span {
  font-size: 18px;
  font-weight: 600;
  display: block;
  width: 100%;
  margin: 0 0 26px;
}

.amenities-address .input-group {
  width: 49%;
  margin: 0 0 24px;
}

.amenities-address .input-group label {
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
  display: block;
}

.amenities-address .input-group .amenities-img {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amenities-address .input-group .amenities-img span {
  width: 50px;
  height: 50px;
  border: 1px solid #e0e0e0;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.amenities-img span i {
  font-weight: bold;
  font-size: 22px;
  color: #bdbdbd;
}

.amenities-address .input-group .amenities-img input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 15px;
}

.amenities-details .amenities-img i {
  color: #219653;
}

.amenities-address .input-group .amenities-img input::placeholder {
  color: #bdbdbd;
}

.input-group span i {
  position: absolute;
  display: contents;
  color: #bdbdbd;
  font-size: 22px;
}

.image-uploader {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding-bottom: 20px;
}

.upload-img {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.delete-icon-image {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  border: none;
  background-color: #e0e0e0;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  pad: 10px;
  bottom: 15px !important;
  right: 10px;
  cursor: pointer;
}

.delete-icon-image i {
  text-align: center;
  color: red !important;
}

.upload-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container:hover .delete-icon {
  display: block;
}

.discription {
  width: 100%;
  margin: 0 0 31px;
}

.discription label {
  display: block;
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
}

.discription textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  min-height: 100px;
  padding: 16px 24px 0;
}

.discription textarea::placeholder {
  font-family: "Rubik", sans-serif;
  color: #e0e0e0;
}

.location-save-btn button {
  width: 90px;
  height: 40px;
  text-align: center;
  border: none;
  font-size: 12px;
  font-weight: bold;
  color: #828282;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.upload-img-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.upload-img-main .active-location {
  margin: 0 40px 0 0;
  position: relative;
}

.upload-img-main .active-location .delete-loaction {
  width: auto;
  position: absolute;
  bottom: 8px;
  right: 5px;
}

.upload-img-main .active-location .delete-loaction span {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  color: #eb5757;
  cursor: pointer;
}

.upload-img-main .upload-img {
  width: 100%;
  border: 2px dotted #bdbdbd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-img-main .img-head {
  width: 100%;
  margin: 0 0 26px;
}

.upload-img-main .img-head label {
  color: #333;
  font-weight: 600;
  font-size: 18px;
  display: block;
}

.upload-img-main .img-head label span {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
  display: inline-block;
  margin: 0 0 0 5px;
}

.upload-img-main .upload-img input {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-img-main .upload-img .fa {
  color: #828282;
}

.upload-img-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.upload-img-main .active-location {
  margin: 0 40px 0 0;
  position: relative;
}

.upload-img-main .active-location .delete-loaction {
  width: auto;
  position: absolute;
  bottom: 8px;
  right: 5px;
}

.upload-img-main .active-location .delete-loaction span {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  color: #eb5757;
  cursor: pointer;
}

.upload-img-main .upload-img {
  width: 100%;
  max-width: 147px;
  height: 147px;
  border: 2px dotted #bdbdbd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-img-main .img-head {
  width: 100%;
  margin: 0 0 26px;
}

.upload-img-main .img-head label {
  color: #333;
  font-weight: 600;
  font-size: 18px;
  display: block;
}

.upload-img-main .img-head label span {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
  display: inline-block;
  margin: 0 0 0 5px;
}

.upload-img-main .upload-img input {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-img-main .upload-img .fa {
  color: #828282;
}

.address {
  width: 100%;
  display: inline-block;
}

.address span,
.area span,
.Local-amenities span {
  font-size: 18px;
  font-weight: 500;
  display: block;
}

.address span {
  margin: 0 0 3px;
}

.location-details-main .about-location .area {
  margin: 36px 0;
}

.location-details-main .about-location .area span {
  margin: 0 0 14px;
}

.address p,
.area p {
  line-height: 26px;
  text-align: justify;
  color: #828282;
}

.Local-amenities {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.Local-amenities span {
  margin: 0 0 21px;
  width: 100%;
}

.amenities-details {
  width: 33.33%;
  display: flex;
  margin: 0 0 42px;
}

.amenities-details .amenities-img {
  margin: 0 9px 0 0;
}

.amenities-details .amenities-head label {
  font-weight: 500;
  font-size: 14px;
  color: var(--font-color);
}

.amenities-details .amenities-head p {
  font-size: 14px;
  color: #828282;
  margin: 7px 0 0;
}

.amenities-details .amenities-img span {
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amenities-details .amenities-img img {
  width: 16px;
  height: 19px;
  object-fit: contain;
}

.location-save-btn .save-btn {
  background-color: var(--btn-color);
  color: #fff;
}

/* Location List CSS Start */
.location_list {
  width: 100%;
  background: #f9fcfe;
  padding: 0 30px;
}

.loaction-list-head {
  display: flex;
  justify-content: space-between;
  background: white !important;
  padding: 15px 0;
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  height: 40px;
  padding-left: 30px;
}

.add-location-button .break_button {
  background-color: #ee404c;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 115px;
  height: 100%;
}
.add-location-button .merge_button {
  background-color: #219653;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  width: 115px;
  height: 100%;
}
.merge_button_one {
  background-color: #219653;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  width: 115px;
  height: 100%;
}
.search-bar_one select {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  height: 40px;
  width: 230px;
  padding: 0px 10px;
}
.search-bar input,
.search-bar select {
  border: 0px;
  width: 100%;
}

.search-bar input:focus {
  border: none !important;
  outline: none;
}

.search-bar i {
  position: absolute;
  left: 10px;
  color: #666;
  cursor: pointer;
}

.add-location button {
  background-color: #219653;
  color: #fff;
  padding: 7px;
  border: 0px;
  height: 40px;
  font-weight: 600;
}

.main_image {
  height: 300px !important;
}

.next-previous-slide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slides-button ul li {
  list-style-type: none;
  display: inline;
  margin: 10px;
  cursor: pointer;
  right: 0px;
}

.slides-button ul li button {
  border: 0px;
  margin: 0 5px;
  background: none;
}

.slides-button ul li button.active {
  background: #333 !important;
  height: 20px !important;
}

.table > :not(caption) > * > * {
  border-bottom-width: none !important;
}

.location_list-table tbody tr td {
  vertical-align: middle;
  position: relative;
  font-weight: 600;
  /* margin-bottom: 0px !important; */
}

.location_list-table thead tr {
  border-top: 1px solid #ededed;
}

.location_list-table thead tr th {
  color: #828282;
  padding-top: 25px !important;
}

.location_list-table tbody tr td p {
  font-weight: normal;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: -1px !important;
  flex-grow: 1;
  text-align: center;
}

.location_list-table tbody tr td .status_button {
  /* border: 2px solid #219653 !important; */
  height: 28px;
  color: #219653;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  width: 87px;
  cursor: default;
  font-size: 12px;
}

.location_list-table tbody tr td img {
  width: 50px;
  height: 50px;
}

.location_list-table tbody tr .datetime-cell span {
  text-align: left;
}

.location_list-table tbody tr td button {
  /* position: absolute; */
  margin: 0;
  border: 0px;
  background-color: #fff !important;
  padding: 0px !important;
}

.details_dropdown a {
  text-decoration: none;
  color: black;
}

.active_one {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  background-color: #ffffff !important;
  color: black !important;
}

.active {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  background-color: #219653;
  color: #fff !important;
}

.dashboard-menu .dashboard-options ul li .active svg {
  fill: #fff;
}

/* Location details CSS Start */
.about-heading h3 {
  text-transform: capitalize;
  font-size: 22px;
}

.about-heading span {
  color: #828282;
  font-weight: 500;
  font-size: 14px;
}

.about-heading .dot_span {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background-color: #bdbdbd;
  vertical-align: middle;
  margin: 0 5px;
}

.details-img .view-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-img .selectable-img ul li {
  list-style-type: none;
  width: 56px !important;
  height: 56px !important;
}

.details-img .selectable-img ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-img .selectable-img ul {
  display: flex;
  justify-content: start;
  padding: 0px;
  margin: 5px 0;
}

.about-location-head {
  display: flex;
  justify-content: space-between;
}

.location-edit ul li {
  display: inline;
  margin: 0px 10px;
}

.about-discription h4 {
  font-size: 18px;
}

.about-code h5 {
  font-size: 16px;
  color: #828282;
}

.about-code span {
  font-size: 12px;
}

.about-discription p {
  text-align: justify;
  color: #828282;
}

.about-phases {
  width: 100%;
  margin: 0 0 43px;
}

.about-phases h4 {
  font-size: 18px;
}

.about-phases ul {
  padding: 0;
  display: flex;
  margin: 18px 0 0 0;
  flex-wrap: wrap;
}

.about-phases ul li {
  list-style-type: none;
}

.about-phases ul li a {
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  background-color: #f2f2f2;
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  margin: 0 10px 10px 0;
}

/* ADD PLOT DETAILS CSS START */
.add-info {
  width: 100%;
  background-color: #fff;
}

.add-info .info-heading {
  width: 100%;
  padding: 23px 30px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
}

.add-info .info-heading h3 {
  font-size: 20px;
  font-weight: 600;
}

.add-info .info-all-details {
  width: 100%;
  padding: 29px 30px 0;
}

.add-info .info-all-details .site-location {
  width: 100%;
  display: flex;
  gap: 20px;
  margin: 0 0 15px;
}

.add-info .info-all-details .site-location .input-field {
  width: 25%;
}

.add-info .info-all-details .site-location .input-field .for-euro {
  width: 100%;
  display: inline-block;
  position: relative;
}

.add-info .info-all-details .site-location select,
select option {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 500;
}

.add-info .info-all-details .site-location select:invalid,
select option[value=""] {
  font-size: 16px;
  color: #bdbdbd;
  padding: 0 15px;
  text-transform: capitalize;
}

.add-info .info-all-details .site-location select option {
  font-size: 14px;
  font-weight: 500;
}

.add-info .info-all-details .site-location .input-field .for-euro input {
  padding: 0 0 0 48px;
}

.add-info .info-all-details .site-location .input-field .for-euro i {
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
}

.add-info .info-all-details .site-location select {
  border: 1px solid #e0e0e0;
  height: 50px;
  width: 100%;
  padding: 0;
  appearance: none;
  background-position: calc(100% - 16px) center;
}

.add-info .info-all-details .site-location select option {
  color: var(--font-color);
  font-size: 14px;
  font-weight: 500;
}

.input-group-Location {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.amenities-address .input-group-Location label {
  display: block;
  font-weight: 600;
  color: var(--font-color);
}

.amenities-address .input-group-Location .location_search-select {
  margin-top: 20px;
  width: 500px;
}

.css-qbdosj-Input {
  padding: 10px;
}

.css-1jqq78o-placeholder {
  padding: 10px;
}

.css-1hb7zxy-IndicatorsContainer {
  padding-right: 10px;
}

.css-1dimb5e-singleValue {
  padding-left: 10px;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.css-166bipr-Input {
  padding-left: 10px;
}

.add-info .info-all-details .site-location .input-field label {
  display: block;
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
}

.input-textarea label {
  display: block;
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
}

.add-info .info-all-details .site-location .input-field input {
  width: 100%;
  min-height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 24px;
}

.add-info .info-all-details .site-location .input-field input::placeholder {
  color: #bdbdbd;
}

.add-info .info-all-details .discription {
  width: 100%;
  margin: 0 0 31px;
}

.add-info .info-all-details .discription label {
  display: block;
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
}

.add-info .info-all-details .discription textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  min-height: 100px;
  padding: 16px 24px 0;
}

.add-info .info-all-details .discription textarea::placeholder {
  font-family: "Rubik", sans-serif;
  color: #e0e0e0;
}

.add-info .info-all-details .upload-img-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.add-info .info-all-details .upload-img-main .active-location {
  margin: 0 40px 0 0;
  position: relative;
}

.add-info .info-all-details .upload-img-main .active-location .delete-loaction {
  width: auto;
  position: absolute;
  bottom: 8px;
  right: 5px;
}

.upload-img-main .active-location .delete-loaction span {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  color: #eb5757;
  cursor: pointer;
}

.add-info .info-all-details .upload-img-main .upload-img {
  width: 100%;
  max-width: 147px;
  height: 147px;
  border: 2px dotted #bdbdbd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-info .info-all-details .upload-img-main .img-head {
  width: 100%;
  margin: 0 0 26px;
}

.add-info .info-all-details .upload-img-main .img-head label {
  color: #333;
  font-weight: 600;
  font-size: 18px;
  display: block;
}

.add-info .info-all-details .upload-img-main .img-head label span {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
  display: inline-block;
  margin: 0 0 0 5px;
}

.add-info .info-all-details .upload-img-main .upload-img input {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.add-info .info-all-details .upload-img-main .upload-img .fa {
  color: #828282;
}

.info-all-details .plot-address-main label {
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
}

.info-all-details .plot-address-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin: 0 0 56px;
}

.info-all-details .plot-address-main span {
  font-size: 18px;
  font-weight: 600;
  display: block;
  width: 100%;
  margin: 0 0 29px;
}

.info-all-details .plot-address-main input {
  padding: 0 0 0 22px;
  height: 50px;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.info-all-details .plot-address-main input::placeholder {
  color: #bdbdbd;
}

.info-all-details .plot-address-main .plot-address {
  width: 50%;
}

.info-all-details .plot-address-main .plot-address label {
  display: block;
}

.info-all-details .plot-address-main .plot-gps {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gps_boxes {
  display: flex;
}

.gps_boxes .gps-box input,
.gps-box2 input {
  width: 200px;
  display: block;
  margin-top: 10px;
}

.gps_boxes .gps-box2 {
  margin-left: 100px;
}

.info-all-details .plot-address-main .plot-gps .gps-box label {
  margin: 0 0 9px;
  display: block;
}
#mySelect {
  padding-left: 15px;
}

.info-all-details .plot-address-main .plot-gps .plot-gps-head {
  width: 100%;
}

.location-save-btn {
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 20px 0 56px 0;
}

.location-save-btn button {
  width: 90px;
  height: 40px;
  text-align: center;
  border: none;
  font-size: 12px;
  font-weight: bold;
  color: #828282;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.location-save-btn .save-btn {
  background-color: var(--btn-color);
  color: #fff;
}

.location-save-btn .save-btn a {
  color: #fff;
  text-decoration: none;
}

/* PLOT LIST CSS START */
.plot_site-name {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.add-location button a {
  text-decoration: none;
  color: #fff;
}

.add-location .filter_icon {
  background-color: #f2f2f2;
  padding: 12px;
  cursor: pointer;
}

.about-price {
  width: 100%;
  margin: 29px 0 0;
  border-bottom: 1px solid #e0e0e0;
}

.about-location .about-price span {
  font-weight: bold;
  font-size: 20px;
  color: #eb5757;
  display: inline-block;
  margin: 0 0 28px;
}

.about-sizes {
  margin-top: 50px;
  width: 100%;
}

.about-sizes ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0 0 40px;
}

.about-sizes ul li {
  list-style-type: none;
  width: 25%;
  font-size: 14px;
  color: #828282;
}

.about-sizes ul li p {
  font-weight: 500;
  color: var(--font-color);
  margin: 0 0 4px;
}

.about-gps {
  width: 100%;
}

.about-gps span {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.about-gps ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.about-gps ul li {
  list-style-type: none;
  width: 25%;
  font-size: 14px;
  margin: 25px 0 0;
  color: #828282;
}

.about-gps ul li p {
  font-weight: 500;
  color: var(--font-color);
  margin: 0 0 4px;
}

.modal-right {
  transform: translateY(100%);
  /* Start the modal outside of the screen */
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  /* Adjust the width as needed */
  margin: 0;
  margin-right: 0;
}

.show,
.model,
.fade {
  padding-right: 0 !important;
  display: block;
  transition: none !important;
  padding-left: 0px !important;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  /* transform: translate(0, -29px) !important; */
  margin-right: 0px !important;
  margin-top: 0px !important;
  transition: none !important;
}

.editing-mode .show {
  transform: translateX(50%) !important;
}

.editing-mode .modal-dialog {
  margin-right: 45% !important;
}

.editing-mode .modal-content {
  margin-top: 10px;
  width: 900px;
  height: 100% !important;
}

.modal-content {
  height: 100vh !important;
  border-radius: 0px !important;
  border: none !important;
}

.modal.show .modal-right {
  transform: translateY(0) !important;
  /* Slide the modal into the screen */
}

.model_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.model_button .reset-btn {
  width: 90px;
  height: 40px;
  text-align: center;
  border: none;
  font-size: 12px;
  font-weight: bold;
  color: #828282;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.model_button .close-btn {
  width: 90px;
  height: 40px;
  text-align: center;
  border: none;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  background-color: #219653;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.filter-status {
  width: 100%;
  padding: 21px 30px 26px 0;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-wrap: wrap;
}

.filter-status_item {
  width: 100%;
  padding: 21px 30px 26px 0;
  border-bottom: 1px solid #ededed;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.filter-status_item span {
  color: #4f4f4f;
  font-weight: 600;
  display: block;
  width: 100%;
  margin: 0 0 13px;
}

.filter-status_item .input-group {
  width: max-content;
  display: flex;
  align-items: center;
  margin: 0px 24px 0 0;
}

.filter-status_item .input-group input {
  width: 24px;
  height: 24px;
  accent-color: #219653;
}

.filter-status_item .input-group label {
  color: #4f4f4f;
  font-size: 14px;
  display: block;
  margin: 0 0 0 8px;
}

.filter-status span {
  color: #4f4f4f;
  font-weight: 600;
  display: block;
  width: 100%;
  margin: 0 0 13px;
}

.filter-status .input-group {
  width: max-content;
  display: flex;
  align-items: center;
  margin: 0px 24px 0 0;
}

.filter-status .input-group input {
  width: 24px;
  height: 24px;
  accent-color: #219653;
}

.filter-status .input-group label {
  color: #4f4f4f;
  font-size: 14px;
  display: block;
  margin: 0 0 0 8px;
}

.filter-date {
  width: 100%;
  padding: 27px 30px 27px 0;
  display: flex;
  gap: 15px;
}

.filter-date .input-group {
  width: 50%;
}

.filter-date .input-group label {
  display: block;
  font-weight: 600;
  color: #4f4f4f;
  margin: 0 0 7px;
}

.filter-date .input-group input {
  height: 44px;
  border: 1px solid #e0e0e0;
  width: 100%;
  padding: 0 15px;
}

/* ADD BLOG DETAILS CSS START  */

.blog-info-main {
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
}

.blog-info-main .main_container {
  padding-bottom: 150px !important;
}

.blog-info-main label {
  font-weight: 600;
  color: #4f4f4f;
  margin: 0 0 9px;
  display: block;
}

.blog-head {
  width: 100%;
  border-bottom: 1px solid #ededed;
  padding: 23px 40px;
}

.blog-head h3 {
  font-size: 20px;
  font-weight: 600;
}

.blog-info {
  width: 100%;
  display: inline-block;
  padding: 29px 40px 0;
}

.blog-info input,
select {
  height: 50px;
  padding: 0 20px;
  border: 1px solid #e0e0e0;
  width: 100%;
}

.blog-info select {
  border: 1px solid #e0e0e0;
  height: 50px;
  width: 100%;
  padding: 10px 25px 9px 0;
  appearance: none;
  /* background: url("../images/angle-down.png") no-repeat right center; */
  background-position: calc(100% - 16px) center;
}

.blog-info .title {
  width: 100%;
}

.blog-info .title label {
  display: block;
}

.blog-info .blog-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px;
  gap: 15px;
}

.blog-info .blog-option .input-group-button {
  width: 33.33%;
}

.blog-info .blog-option .input-group input::placeholder {
  color: #bdbdbd;
}

/* .blog-info .blog-option .input-group-button button {
  padding: 11px;
  border: none;
  background-color: #e0e0e0;
  margin: 12px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.blog-info .blog-option .input-group-button button i {
  color: var(--font-color);
  margin: 0 0 0 6px;
  cursor: pointer;
  font-size: 18px;
}

.blog-banner {
  width: 100%;
}

.content_tag {
  font-size: 20px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  margin-top: 50px;
  margin-bottom: 0px;
}

.blog-banner span {
  font-size: 20px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  display: block;
  margin: 0 0 17px;
}

.blog-banner .img-upload {
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-banner .img-upload input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.blog-banner .img-upload .upload-text {
  color: #828282;
}

.blog-banner .img-upload .upload-text span {
  display: block;
  margin: 9px 0 6px;
}

.blog-content {
  width: 100%;
  height: 366px;
  margin: 55px 0 93px;
}

.blog-content span {
  margin: 0 0 0px;
}

.blog-delete_one {
  width: 55px;
  height: 40px;
  padding: 4px 10px;
  background-color: #219653;
}

.blog-delete_two {
  width: 55px;
  height: 40px;
  padding: 4px 10px;
  background-color: #219653;
}

.blog-delete_one button {
  border: 0px !important;
  background: transparent !important;
  color: #ffffff !important;
  font-size: 20px !important;
}

.blog-delete_two button {
  border: 0px !important;
  background: transparent;
  color: #ffffff;
  font-size: 20px;
}

.blog-content .add-content,
.add-content-nextBlog {
  width: 100%;
  height: 366px;
  border: 2px dotted #219653;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
}

.blog-content .add-content .add-img-text {
  width: auto;
  text-align: center;
  margin: 37px 23px 37px 0;
}

.blog-content .add-content.blog-active .add-img-text {
  display: none;
}

.blog-content .add-content.blog-active .blog-content-view {
  opacity: 1;
  visibility: visible;
  position: static;
}

.blog-content .add-content .add-img-text span {
  width: 112px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  margin: 0 0 9px;
}

.blog-content .add-content .add-img-text label {
  margin: 0;
  font-size: 18px;
  color: #000;
}

.blog-content .blog-content-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 64px 50px 44px 41px;
  gap: 50px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-content .blog-content-view .blog-img {
  width: auto;
  position: relative;
}

.blog-content .blog-content-view .blog-img img {
  vertical-align: top;
}

.blog-content .blog-content-view .blog-img .blog-delete {
  position: absolute;
  bottom: 2px;
  right: 0;
}

.blog-content .blog-content-view .blog-discription {
  width: 100%;
  border: 2px dotted #219653;
  padding: 26px 10px 0 39px;
}

.blog-content .blog-content-view .blog-discription h3 {
  font-weight: 500px;
  margin: 0 0 15px;
}

.blog-content .blog-content-view .blog-discription p {
  line-height: 24px;
}

.blog-content .blog-content-view .blog-discription .p-spacing {
  margin: 0 0 20px;
}

.blog-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.blog-btn button {
  height: 40px;
  font-weight: bold;
  font-size: 12px;
  border: none;
  text-align: center;
}

.blog-btn .blog-cancel button {
  width: 90px;
  background-color: #f2f2f2;
  color: #828282;
}

.blog-btn .blog-save {
  width: auto;
  display: flex;
}

.blog-btn .blog-save button {
  background-color: #219653;
  width: 90px;
  color: #fff;
  margin: 0 0 0 10px;
}

.blog-btn .blog-save .draft-btn {
  width: 118px;
  background-color: #4f4f4f;
}

.add_content-row {
  height: 366px;
  padding: 30px;
  display: flex;
  flex-direction: row;
}

.selected_image {
  width: 100%;
  height: 100%;
}

.add_content-row textarea {
  width: 100%;
  color: #4f4f4f;
  border: 1px dashed #219653;
  height: 100%;
  padding: 10px;
}

.add_content-row textarea:focus {
  outline: none;
}

.blog_image-upload {
  position: relative;
  height: 100%;
}

.row-left {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.row-right {
  display: flex !important;
  flex-direction: row !important;
}

.selected_image {
  max-width: 100%;
  height: auto;
}

.img-upload {
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #219653;
}

.img-upload input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.img-upload .upload-text {
  color: #828282;
}

.img-upload .upload-text span {
  display: block;
  margin: 9px 0 6px;
}

.uploaded-image {
  position: relative;
  width: 100%;
  height: 258px;
}

.uploaded-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  color: red;
  border: none;
  background-color: #e0e0e0;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  bottom: 10px !important;
  right: 10px;
}

/* BLOG LIST PAGE CSS */
.location_list-table tbody tr td .status_button-draft {
  border: 2px solid #f2c94c !important;
  padding: 10px !important;
  color: #f2c94c;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  width: 87px;
  font-size: 12px;
}

/* BLOG DETAILS PAGE CSS START  */

.Blog_heading span {
  font-weight: 500;
}

.Blog_heading h3 {
  font-size: 22px;
  text-transform: capitalize;
}

.Blog_heading .circle {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background-color: #bdbdbd;
  vertical-align: middle;
  margin: 0 5px;
}

.edit_delete-icon {
  text-align: end;
}

.edit_delete-icon i {
  padding: 10px;
  color: #828282;
  cursor: pointer;
}

.blog_image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.tags_heading {
  font-size: 18px;
}

.tags ul {
  padding: 0px;
}

.tags ul li {
  display: inline-block;
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  text-align: center;
  margin: 0 10px 10px 0;
  padding: 10px 20px;
}

.blog_details-image img {
  width: 100%;
  height: 100%;
}

/* ADD SALES AGENT DETAILS CSS  */
.agent-photo {
  width: 100%;
  height: 240px;
}

.agent-photo .agent-upload {
  width: 100%;
  height: 100%;
  border: 1px dotted #e0e0e0;
  background-color: #fcfcfc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agent-photo .agent-upload .upload-icon {
  text-align: center;
}

.agent-photo .agent-upload .upload-icon i {
  display: block;
}

.agent-photo .agent-upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 11;
  cursor: pointer;
}

.agent_input input[type="text"] {
  border: 1px solid #e0e0e0;
  height: 50px;
  width: 100%;
  padding: 0 0 0 10px;
}

.agent_input label {
  color: var(--font-color);
  font-weight: 600;
  display: block;
  margin: 0 0 9px;
}

.agent_input input[type="email"] {
  border: 1px solid #e0e0e0;
  min-height: 50px !important;
  width: 100%;
  padding: 0 0 0 10px;
}

.agent_input .agent_bio {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 10px;
}

.integration_section {
  margin-top: 50px;
}

.integration_section h3 {
  font-weight: 600;
  font-size: 20px;
  width: 100%;
}

.agent_input input ::placeholder {
  color: red;
}

/* .agent-intregate {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: left;
} */

.agent-intregate h3 {
  font-weight: 600;
  font-size: 20px;
  width: 100%;
}

/* .agent_col label:active {
  background: none !important;
} */
/* .agent-intregate ul {
  display: flex;
  align-items: center;
  margin: 25px 0;
  width: 100%;
} */

/* .agent-intregate ul li {
  display: inline-block;
  border: 1px solid #bdbdbd;
  width: 145px;
  height: 60px;
  margin: 0 15px 0 0;
  text-align: center;
  line-height: 60px;
} */

/* .agent-intregate .input-group {
  width: 45%;
} */

/* .agent-intregate .input-group input::placeholder {
  color: var(--font-color);
  font-size: 16px;
} */

/* .agent-intregate .zoom-link {
  width: 100%;
}
.agent-intregate:active {
  background: none !important;
} */
.agent_save-button {
  margin-top: 100px;
}

.nav_item {
  background: none !important;
}

/* .agent-intregate label {
  font-weight: 600;
  color: var(--font-color);
  background-color: none !important;
  margin: 0 0 9px;
  display: block;
} */
.agent-intregate ul li img {
  vertical-align: inherit;
}

/* .agent-intregate ul li img {
  cursor: pointer;
  margin-right: 0px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agent-intregate ul li.company-active {
  border: 1px solid #006bff;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .agent-intregate ul li.company-active .company-logo-first {
  display: none;
} */
.nav-tabs {
  border: none !important;
  border-color: white;
}

.nav-tabs:active {
  border: none !important;
}

.nav-item a {
  border: none !important;
}

/* .agent-intregate ul li.company-active .company-logo-second {
  display: none;
} */
/* .company-active img {
  border: 2px solid #007bff;
  padding: 10px;
}
.visible {
  display: block;
}

.hidden {
  display: none;
} */

.agent-intregate .zoom-link input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 0 0 22px;
}

.agent-intregate .zoom-link input::placeholder {
  color: var(--font-color);
}

.agent-btn {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 20px 0 56px;
}

.agent-btn a {
  width: 90px;
  height: 40px;
  background-color: #f2f2f2;
  color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 10px;
}

.agent-btn a.active {
  background-color: #219653;
  color: #fff;
}

.agent_email h5 {
  font-weight: 500;
  font-size: 16px;
  color: var(--font-color);
}

.integrate {
  width: 100%;
  margin: 64px 0 0;
}

.integrate h3 {
  font-weight: 600;
  font-size: 20px;
}

.Login-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Login-details span {
  display: block;
  width: 100%;
  margin: 26px 0 22px;
}

.agent-login {
  width: 40%;
  margin: 0 0 19px;
}

.agent-login label {
  font-weight: 500;
  font-size: 14px;
  color: var(--font-color);
}

.agent-login p {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  margin: 4px 0 0;
}

.agent-login a {
  color: var(--font-color);
}

.dashboard-right-details .admin-meeting-main {
  width: 100%;
  margin: 32px 0 0;
  padding: 0 23px;
}

.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .vertical-line-green,
.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .vertical-line-red,
.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .vertical-line-yellow {
  width: 3px;
  height: 100%;
  background-color: #219653;
  position: absolute;
  top: 0;
  left: 0;
}

.react-calendar {
  width: 250px !important;
}

.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .vertical-line-yellow {
  background-color: #f2c94c !important;
}

.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .vertical-line-red {
  background-color: #eb5757 !important;
}

.dashboard-right-details .admin-meeting-main .admin-meeting-info {
  width: 100% !important;
  border: 1px solid #f2f2f2 !important;
  margin: 0 0 20px !important;
  padding: 14px 0 14px 22px !important;
  position: relative !important;
  height: 125px !important;
}

.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .meeting-profile {
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .meeting-profile
  span
  img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.admin-meeting-info span {
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-top: 0px !important;
}

.dashboard-right-details .admin-meeting-main .admin-meeting-info h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin: 17px 0 6px !important;
}

.dashboard-right-details .admin-meeting-main .admin-meeting-info .meeting-time {
  width: auto;
  /* display: flex;
  align-items: center; */
  gap: 15px;
}

.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .meeting-time
  div {
  font-size: 12px !important;
  color: #828282 !important;
  display: flex !important;
  align-items: center !important;
}

.dashboard-right-details
  .admin-meeting-main
  .admin-meeting-info
  .meeting-time
  div
  img {
  margin: 0 6px 0 0;
}

/* ADD ADDITIONAL ITEMS DETAILS CSS */
.price_box {
  position: relative;
}

.price_box input {
  padding: 0 30px !important;
}

.price_box i {
  position: absolute;
  top: 35%;
  left: 5%;
}

.info-all-details h5 {
  font-weight: 600;
  color: #4f4f4f;
}

.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(158, 158, 158, 0.5);
  z-index: 1000;
  display: none;
}

/* Your custom CSS */
.custom-modal .modal-content {
  /* Add your custom styling here */
  /* For example, change the position, add a background, adjust width, etc. */
  position: absolute;
  width: 340px;
  height: 220px !important;
  margin-top: 50%;
  margin-right: 100px !important;
  color: black;
  margin-left: -65%;
}

.modal-backdrop.show {
  opacity: 0 !important;
  color: #ccc;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 85%);
  opacity: 0.5;
  visibility: visible;
  z-index: 999;
}

.overlay.buyers-main-active {
  opacity: 1;
  visibility: visible;
}

.no-scroll {
  overflow: hidden !important;
}

.search-bar .clear-icon {
  position: absolute;
  left: 90%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
}

/* ADDITIONAL ITEM ADD DETAILS PAGE CSS  */
.item-type input {
  accent-color: #219653;
}

/* ADD BLOG DETAILS CSS  */

ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 50px;
  margin: 0;
  font-size: 16px;
  width: 100%;
  border: 1px solid #e0e0e0;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #e0e0e0;
  color: #000000;
  font-size: 16px;
  display: inline-block;
  margin-top: 10px !important;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

.ReactTags__selected {
  width: 330px !important;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #e0e0e0;
  font-size: 10px;
  font-weight: 900 !important;
  background-color: #333;
  width: 20px;
  padding: -5px;
  height: 20px;
  margin-left: 5px !important;
  border-radius: 50%;
}

.agent-intregate {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: left;
}

.agent-intregate h3 {
  font-weight: 600;
  font-size: 20px;
  width: 100%;
}

.agent-intregate ul {
  display: flex;
  align-items: center;
  margin: 25px 0;
  width: 100%;
}

.agent-intregate ul li {
  display: inline-block;
  border: 1px solid #bdbdbd;
  width: 145px;
  height: 50px;
  margin: 0 15px 0 0;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
}

.agent-intregate ul li .company-logo-first,
.agent-intregate ul li .company-logo-second {
  display: none;
}

.agent-intregate ul li.company-active img {
  display: none;
}

.agent-intregate ul li.company-active {
  border: 1px solid #006bff;
}

.agent-intregate ul li.company-active .company-logo-first {
  display: unset;
}

.agent-intregate ul li.company-active .company-logo-second {
  display: unset;
}

.agent-intregate .input-group {
  width: 45%;
}

.agent-intregate .input-group input::placeholder {
  color: var(--font-color);
  font-size: 16px;
}

.agent-intregate .zoom-link {
  width: 100%;
}

.agent-intregate .zoom-link label {
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
  display: block;
}

.agent-intregate .zoom-link input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 0 0 22px;
}

.agent-intregate .zoom-link input::placeholder {
  color: var(--font-color);
}

/* Styling for the alternating layout */
.container_one {
  display: flex;
  gap: 30px;
  flex-direction: row;
  padding: 20px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.image {
  width: 353px;
  height: 273px;
  object-fit: cover;
  margin-right: 20px;
}

.image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

/* Alternating background colors for even and odd containers */

.odd {
  display: flex;
  flex-direction: row-reverse;
}

/* Additional styling for images */
.image img {
  max-width: 100%;
  height: auto;
}

/* Additional styling for content */
.content {
  font-size: 16px;
  padding-left: 0px;
}

/* Add these styles to your CSS */
.status_button {
  /* Common styles for both status buttons */
  border: none;
  padding: 5px 10px;
  border-radius: 0px;
  font-weight: bold;
}

.published {
  color: #219653;
  border: 2px solid #219653 !important;
}

.draft {
  /* background-color: yellow; */
  color: #f2c94c !important;
  border: 2px solid #f2c94c !important;
}

.user-blocked {
  /* background-color: yellow; */
  color: rgb(248, 89, 89) !important;
  border: 2px solid rgb(248, 89, 89) !important;
}

.details-img {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}

.view-img {
  /* position: relative; */
  cursor: pointer;
}

.view-img img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

.selectable-img {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  /* display: none; */
  transition: display 0.3s ease;
}

/* .view-img:hover .selectable-img {
  display: block;
} */

.selectable-img ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.selectable-img li {
  /* width: 40px;
  height: 40px; */
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.selectable-img li.selected {
  border-color: #219653;
}

.selectable-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Define the styles for the green "Available" button */
.available {
  border: 2px solid #219653 !important;
  color: white;
  border: none;
}

/* Define the styles for the red button */
.sold {
  border: 2px solid red !important;
  color: red !important;
  border: none;
}

/* CSS FOR ORDER DETAILS PAGE */
.order-details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 15px;
  margin: 0 0 20px;
}

.order-info-main .order-mark {
  width: auto;
}

.order-info-main .user-order button.user-order-btn {
  width: 110px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #219653;
  color: #fff;
  font-weight: 500;
  line-height: 22px;
  cursor: auto;
}

.order-info-main .user-order button.user-order-btn img {
  margin: 0 5px 0 0;
}

.order-info-main .order-gps {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.order-gps {
  display: flex;
  justify-content: space-between;
}

.order-info-main .order-gps a {
  font-weight: 600;
  font-size: 14px;
  color: #219653;
  display: flex;
  text-decoration: none;
  align-items: center;
}

.order-info-main .order-gps a .fa-map-o {
  font-size: 16px;
  margin: 0 3px 0 0;
}

.order-info-main .order-gps a .fa-angle-right {
  margin: 0 3px;
  font-size: 18px;
}

.order-info-main .order-mark button img {
  margin: 0 6px 0 0;
}

.about-location-head .about-heading h3 .pending-order {
  background-color: #fff9e7;
  border: 1px solid #f2c94c !important;
  color: #f2c94c;
  width: 77px;
  height: 22px;
  border-radius: 10px;
  font-size: 14px;
}

.location-details-main .about-location .order-price {
  border-bottom: none;
}

.location-details-main .about-location .order-price span {
  margin: 0;
}

.location-details-main .about-location .order-discription {
  margin-top: 14px;
}

.location-details-main .about-location .order-discription .p-spacing {
  margin: 0 0 20px;
}

/* .customer-order {
  width: 25%;
} */

.customer-order .customer-details {
  width: 100%;
  background-color: #fff;
  /* padding: 30px 32px 54px; */
  margin: 0 0 14px;
}

.customer-order .customer-details h2 {
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 48px;
  font-family: "Inter", sans-serif;
}

.customer-order .customer-details .customer-profile {
  width: 100%;
}

.customer-order .customer-details .customer-profile .customer-img {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.customer-order .customer-details .customer-profile .customer-img img {
  width: 60px;
  height: 60px;
}

.customer-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.customer-order
  .customer-details
  .customer-profile
  .customer-img
  .customer-name {
  margin: 0 0 0 17px;
}

.customer-order
  .customer-details
  .customer-profile
  .customer-img
  .customer-name
  span {
  font-weight: 500;
  text-transform: capitalize;
}

.customer-order
  .customer-details
  .customer-profile
  .customer-img
  .customer-name
  p {
  color: #4f4f4f;
  font-size: 14px;
  margin: 8px 0 0;
}

.customer-order .customer-details .customer-profile .customer-contact {
  width: 100%;
}

.customer-order .customer-details .customer-profile .customer-contact span {
  color: #219653;
  font-weight: 500;
  font-size: 14px;
}

.customer-order .customer-details .customer-profile .customer-contact ul {
  margin: 28px 0 0;
  padding: 0px;
}

.customer-order .customer-details .customer-profile .customer-contact ul li {
  margin: 0 0 15px;
  list-style-type: none;
}

.customer-order .customer-details .customer-profile .customer-contact ul li a {
  color: #4f4f4f;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.customer-order
  .customer-details
  .customer-profile
  .customer-contact
  ul
  li
  a
  i {
  margin: 0 15px 0 0;
  color: #828282;
  font-size: 20px;
}

.customer-documents {
  width: 100%;
  background-color: #fff;
  padding: 30px 0px;
  margin-top: 140px;
}

.customer-documents .documents-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 50px 0 44px;
}

.customer-documents .documents-head h2 {
  font-weight: 600;
  font-size: 22px;
}

.customer-documents .documents-head button {
  font-size: 12px;
  font-weight: 600;
  width: 59px;
  height: 30px;
  background-color: #219653;
  border: none;
  color: #fff;
}
.customer-documents .pdf-document {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* column-gap: 20px; */
  flex-wrap: wrap;
}
.customer-documents .pdf-document .pdf-file {
  width: 46%;
  border: 1px solid #e0e0e0;
  padding: 22px 0 18px;
  position: relative;
  text-align: center;
  margin: 0 0 24px;
}
.pdf-file image {
  width: 50px !important;
}
.customer-documents .pdf-document .pdf-file span {
  display: block;
  margin: 21px 0 0;
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 500;
}
.customer-documents .pdf-document .pdf-file span i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #bdbdbd;
  cursor: pointer;
}
.customer-documents .add-document {
  width: 100%;
  max-width: 146px;
  height: 158px;
  border: 2px dotted #219653;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.customer-documents .hide-document {
  opacity: 0;
  visibility: hidden;
}
.customer-documents .add-document i {
  color: #219653;
  font-size: 25px;
}
.customer-documents .add-document input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.order-mark button.start {
  width: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 0px;
  background: transparent;
  border: 1px solid #219653;
  font-weight: 600;
  font-size: 12px;
  color: #219653;
}

/* .order-mark button:hover {
  background: transparent;
  border: 1px solid #219653;
  font-weight: 600;
  font-size: 12px;
  color: #219653;
} */

.order-mark button img {
  margin: 0 6px 0 0;
}

.completed-button.start {
  width: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: transparent;
  border: 1px solid #219653;
  font-weight: 600;
  font-size: 12px;
  color: #219653;
}

.completed-button.updated {
  background-color: #219653 !important;
  color: white !important;
}

/* dashboard.css */

.status_button-order.yellow {
  cursor: auto;
  border: 1px solid #f2c94c !important;
  color: #f2c94c !important;
  height: 28px !important;
  font-size: 12px !important;
  font-family: "Rubik", sans-serif !important;
  font-weight: 600 !important;
  width: 87px;
  font-size: 12px;
}

.status_button-order.green {
  cursor: auto;
  border: 1px solid #219653 !important;
  color: #219653 !important;
  height: 28px !important;
  font-size: 12px !important;
  font-family: "Rubik", sans-serif !important;
  font-weight: 600 !important;
  width: 87px;
  font-size: 12px;
}

.status_button.red {
  background: green !important;
  border: none !important;
  width: 150px !important;
  height: 50px !important;
  font-size: 12px !important;
}

.mark-complete {
  color: #219653;
  /* Green text color */
  background-color: transparent;
  /* Transparent background */
  border: 1px solid #219653;
  /* Green border */
  height: 28px !important;
  font-size: 12px !important;
  font-family: "Rubik", sans-serif !important;
  font-weight: 600 !important;
  width: 154px;
}

/* Red background button for "Completed" */
.completed {
  color: #fff;
  background-color: #219653;
  border: none;
  border-radius: 15px;
  height: 30px !important;
  font-size: 12px !important;
  font-family: "Rubik", sans-serif !important;
  font-weight: 600 !important;
  width: 110px;
}

/* DASHBOARD CSS */
.dashboard-main {
  width: 100%;
  /* display: flex; */
  margin: 0 0 51px;
  /* gap: 19px; */
}

.dashboard-left {
  width: calc(100%);
}

.dashboard-left .dashboard-main-details {
  width: 100%;
  display: flex;
  padding-top: 25px;
}

.dashboard-left .dashboard-main-details .dashboard-details-left {
  width: 50%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col {
  width: 100%;
  margin: 0 -12px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading.p-spacing {
  margin: 0 0 24px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col:last-child
  .dashboard-details-heading
  .details-img
  span {
  background-color: rgba(235, 87, 87, 16%);
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col:nth-child(3)
  .dashboard-details-heading
  .details-img
  span {
  background-color: rgba(155, 81, 224, 10%);
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col:nth-child(2)
  .dashboard-details-heading
  .details-img
  span {
  background-color: rgba(242, 153, 74, 10%);
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col {
  width: 50%;
  float: left;
  padding: 0 8px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  height: 130px !important;
  display: flex;
  height: 200px;
  border: 1px solid #f2f2f2;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading {
  width: calc(100% - 49px);
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  span {
  font-weight: 700;
  font-size: 16px;
  color: var(--font-color);
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  h3 {
  font-size: 22px;
  font-weight: bold;
  margin: 13px 0 0;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  p {
  font-size: 12px;
  font-weight: 500;
  color: var(--font-color);
  margin: 17px 0 0;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  p
  span {
  font-weight: 600;
  /* color: var(--btn-color); */
  margin: 0 0 0 7px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  p
  span.down-graph {
  color: #eb5757;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .main-heading
  span.block-content {
  display: block;
  width: 100%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .details-img {
  width: 49px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .details-img
  span {
  width: 100%;
  height: 49px;
  background-color: rgb(242 201 76 / 16%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-left
  .dashboard-main-col
  .dashboard-details-col
  .dashboard-details-heading
  .details-img
  span
  img {
  width: auto;
  height: auto;
}

.dashboard-left .dashboard-main-details .dashboard-details-right {
  width: 23%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main {
  width: 100%;
  background-color: #fff;
  height: 100%;
  padding: 20px;
  border: 1px solid #f2f2f2;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-head {
  width: 100%;
  display: flex;
  /* gap: 20px; */
  align-items: center;
  justify-content: space-between;
  /* align-items: center; */
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-head
  h2 {
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 30px;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-img {
  width: 100%;
}

.dashboard-left
  .dashboard-main-details
  .dashboard-details-right
  .dashboard-graph-main
  .graph-img
  img {
  width: 100%;
  object-fit: contain;
  vertical-align: top;
  height: 193px;
}

.dashboard-left .dashboard-order-details {
  width: 100%;
  margin: 21px 0 0;
}

.dashboard-left .dashboard-order-details .dashboard-orders {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 22px 26px 26px 29px;
}

.dashboard-left .dashboard-order-details .dashboard-orders h2 {
  font-weight: 600;
  font-size: 16px;
}

.dashboard-left .dashboard-order-details .dashboard-orders a {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #219653;
}

.dashboard-left .dashboard-order-details .dashboard-orders a img {
  margin: 0 0 0 6px;
}

.visible-eye-btn {
  margin: 0 0 0 20px;
}

.dashboard-right {
  display: flex;
  padding-top: 25px;
}

td p {
  color: #828282;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin: 5px 0 0;
}

.dashboard-counts a {
  color: #219653;
  text-decoration: none;
  display: flex;
}

.dashboard-right .admin-meeting-main {
  width: 100%;
  display: flex;
  margin: 32px 0 0;
  padding: 0 23px;
}

.dashboard-right .admin-meeting-main .admin-meeting-info .vertical-line-green,
.dashboard-right .admin-meeting-main .admin-meeting-info .vertical-line-red,
.dashboard-right .admin-meeting-main .admin-meeting-info .vertical-line-yellow {
  width: 3px;
  height: 100%;
  background-color: #219653;
  position: absolute;
  top: 0;
  left: 0;
}

.dashboard-right .admin-meeting-main .admin-meeting-info .vertical-line-yellow {
  background-color: #f2c94c !important;
}

.dashboard-right .admin-meeting-main .admin-meeting-info .vertical-line-red {
  background-color: #eb5757 !important;
}

.dashboard-right .admin-meeting-main .admin-meeting-info {
  /* width: 100% !important; */
  width: 33.3%;
  height: 130px !important;
  padding: 14px !important;
  position: relative !important;
}

.dashboard-right .admin-meeting-main .admin-meeting-info .meeting-profile {
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-right
  .admin-meeting-main
  .admin-meeting-info
  .meeting-profile
  span
  img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.admin-meeting-info span {
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-top: 0px !important;
}

.dashboard-right .admin-meeting-main .admin-meeting-info h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin: 17px 0 6px !important;
}

.dashboard-right .admin-meeting-main .admin-meeting-info .meeting-time {
  width: auto;
  /* display: flex; */
  align-items: center;
  gap: 15px;
}

.dashboard-right .admin-meeting-main .admin-meeting-info .meeting-time div {
  font-size: 12px !important;
  font-weight: 700px !important;
  color: #828282 !important;
  display: flex !important;
  align-items: center !important;
}

.dashboard-right .admin-meeting-main .admin-meeting-info .meeting-time div img {
  margin: 0 6px 0 0;
}

/* USER DETAILS CSS */

.agent-details-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 39px 30px 50px 40px;
  margin: 0 0 45px;
}

.agent-profile {
  width: 250px;
}

.agent-details {
  width: calc(100% - 250px);
}

.agent-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agent-head .agent-heading {
  width: auto;
}

.agent-head .agent-heading h2 {
  font-weight: bold;
  font-size: 22px;
  margin: 0 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agent-head .agent-heading h2 button {
  font-size: 12px;
  background-color: #f8fffb;
  border: 1px solid #219653;
  width: 57px;
  height: 20px;
  border-radius: 10px;
  margin: 0 0 0 7px;
}

.agent-head .agent-heading span {
  color: #4f4f4f;
  font-size: 14px;
}

.agent-head .agent-edit {
  width: auto;
}

.agent-head .agent-edit ul li {
  display: inline-block;
}

.agent-head .agent-edit ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  color: #d9d9d9;
}

.agent-contact {
  width: 100%;
  margin: 18px 0 34px;
}

.agent-contact ul {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.agent-contact ul li {
  list-style-type: none;
}

.agent-contact ul li label {
  font-weight: 500;
  font-size: 14px;
  color: var(--font-color);
}

.agent-contact ul li p {
  color: #828282;
  font-size: 14px;
  margin: 0 0 14px;
}

.agent-bio {
  width: 100%;
  display: inline-block;
}

.agent-bio span {
  font-weight: 500;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
}

.agent-bio p {
  line-height: 26px;
  color: #828282;
  margin: 9px 0 0;
}

.integrate {
  width: 100%;
  margin: 64px 0 0;
}

.integrate h3 {
  font-weight: 600;
  font-size: 20px;
}

.Login-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Login-details span {
  display: block;
  width: 100%;
  margin: 26px 0 22px;
}

.agent-login {
  width: 40%;
  margin: 0 0 19px;
}

.agent-login label {
  font-weight: 500;
  font-size: 14px;
  color: var(--font-color);
}

.agent-login p {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  margin: 4px 0 0;
}

.agent-login a {
  color: var(--font-color);
}

.agent-info-main {
  width: 100%;
  background-color: #fff;
}

.agent-info-main .input-group {
  width: 33.33%;
  margin: 0 0 15px;
}

.agent-info-main .input-group label,
.short-bio label {
  color: var(--font-color);
  font-weight: 600;
  display: block;
  margin: 0 0 9px;
}

.agent-info-main .input-group .password {
  position: relative;
  width: 100%;
}

.agent-info-main .input-group .password input {
  padding-top: 10px;
}

.agent-info-main .input-group input {
  border: 1px solid #e0e0e0;
  height: 50px;
  width: 100%;
  padding: 0 0 0 23px;
}

.agent-info-main .input-group .password i {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
  color: #4f4f4f;
}

.agent-info-main .input-group input::placeholder {
  color: #bdbdbd;
}

.agent-info-head {
  width: 100%;
  display: inline-block;
  padding: 23px 0 23px 38px;
  border: 1px solid #ededed;
}

.agent-info-head h2 {
  font-weight: 600;
  font-size: 20px;
}

.agent-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 43px 51px 75px 40px;
}

.agent-info .agent-photo {
  width: 30%;
}

.agent-info .agent-photo .agent-upload {
  width: 250px;
  height: 236px;
  border: 2px dotted #e0e0e0;
  background-color: #fcfcfc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agent-info .agent-photo .agent-upload .upload-icon {
  text-align: center;
}

.agent-info .agent-photo .agent-upload .upload-icon span {
  font-weight: 500;
  font-size: 18px;
  color: #828282;
  margin: 9px 0 0;
}

.agent-info .agent-photo .agent-upload .upload-icon i {
  display: block;
  color: #828282;
  font-size: 22px;
}

.agent-info .agent-photo .agent-upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 11;
  cursor: pointer;
}

.agent-info .agent-info-input {
  width: 70%;
}

.agent-info .agent-info-input .agent-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.short-bio textarea {
  width: 100%;
  height: 80px;
  border: 1px solid #e0e0e0;
  padding: 16px 0 0 22px;
  margin: 0 0 44px;
}

.short-bio textarea::placeholder {
  color: #bdbdbd;
  font-weight: normal;
  font-family: "Rubik", sans-serif;
}

.agent-intregate {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: left;
}

.agent-intregate h3 {
  font-weight: 600;
  font-size: 20px;
  width: 100%;
}

.agent-intregate ul {
  display: flex;
  align-items: center;
  margin: 25px 0;
  width: 100%;
}

.agent-intregate ul li {
  display: inline-block;
  border: 1px solid #bdbdbd;
  width: 145px;
  height: 50px;
  margin: 0 15px 0 0;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
}

.agent-intregate ul li .company-logo-first,
.agent-intregate ul li .company-logo-second {
  display: none;
}

.agent-intregate ul li.company-active img {
  display: none;
}

.agent-intregate ul li.company-active {
  border: 1px solid #006bff;
}

.agent-intregate ul li.company-active .company-logo-first {
  display: unset;
}

.agent-intregate ul li.company-active .company-logo-second {
  display: unset;
}

.agent-intregate .input-group {
  width: 45%;
}

.agent-intregate .input-group input::placeholder {
  color: var(--font-color);
  font-size: 16px;
}

.agent-intregate .zoom-link {
  width: 100%;
}

.agent-intregate .zoom-link label {
  font-weight: 600;
  color: var(--font-color);
  margin: 0 0 9px;
  display: block;
}

.agent-intregate .zoom-link input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 0 0 22px;
}

.agent-intregate .zoom-link input::placeholder {
  color: var(--font-color);
}

.agent-btn {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 20px 0 56px;
}

.agent-btn a {
  width: 90px;
  height: 40px;
  background-color: #f2f2f2;
  color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 10px;
}

.agent-btn a.active {
  background-color: #219653;
  color: #fff;
}

/* CSS for UserList Component */
.order-row {
  margin-bottom: 10px;
}

/* Status styles */
.available-btn.active {
  cursor: auto;
  background: #fff;
  color: #219653 !important;
  border: 1px solid #219653;
  width: 87px;
  font-size: 12px;
  height: 28px;
  display: flex;
  text-align: center !important;
  justify-content: center;
  align-items: center;
}
.pdf-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pdf-name a {
  margin: 21px 5px;
  color: #4f4f4f;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

.available-btn.blocked {
  cursor: auto;
  background: #fff;
  color: red !important;
  border: 1px solid red;
  width: 87px;
  font-size: 12px;
  height: 28px;
  display: flex;
  text-align: center !important;
  justify-content: center;
  align-items: center;
}

/* Block button styles */
.block-btn.active {
  background-color: #bdbdbd;
  color: #333333;
}

.block-btn.blocked {
  background: #ffffff;
  color: red;
  width: 87px !important;
  height: 28px !important;
}

.centered-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.delete-icon-bg {
  background-color: green;
  /* Define your desired background color */
  border-radius: 50%;
  /* To make it circular */
  padding: 5px;
  /* Add padding for spacing */
  cursor: pointer;
  /* Add a pointer cursor for interaction */
  display: inline-flex;
  /* To keep the icon centered */
  align-items: center;
  /* Vertically center the icon */
  justify-content: center;
  /* Horizontally center the icon */
  margin-left: 5px;
  /* Add margin to separate it from the plus icon */
}

.status_button-detail.published {
  color: #219653 !important;
  border: 1px solid #219653;
  font-size: 12px;
  width: 77px;
  height: 20px;
  border-radius: 10px;
  margin: 0 0 0 7px;
}

.status_button-detail.draft {
  color: #f2c94c !important;
  border: 1px solid #f2c94c;
  font-size: 12px;
  width: 77px;
  height: 20px;
  border-radius: 10px;
  margin: 0 0 0 7px;
}

.filter-main .filter-date .input-group .input-date {
  width: 100%;

  position: relative;
}

.filter-main .filter-date .input-group .input-date input {
  height: 44px;

  border: 1px solid #e0e0e0;

  width: 100%;

  padding: 0 15px;
}

.filter-main .filter-date .input-group .input-date input::placeholder {
  font-size: 16px;

  color: #bdbdbd;
}

.filter-main .filter-date .input-group .input-date img {
  position: absolute;

  top: 50%;

  right: 16px;

  transform: translateY(-50%);
}

/* SETTING PAGE CSS */

.setting-main {
  width: 100%;
  display: flex;
  margin: 0 0 50px;
  gap: 13px;
}

.setting-menu {
  width: 300px;
  background-color: #fff;
  padding: 32px 31px 0;
}

.setting-menu span {
  font-size: 14px;
  font-weight: 600;
  color: #828282;
}

.setting-menu ul {
  margin: 22px 0 0;
}

.setting-menu ul li {
  margin: 0 0 16px;
  list-style-type: none;
}

.setting-menu ul li a {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #bdbdbd;
  height: 54px;
  color: #219653;
  font-size: 16px;
  font-weight: 500;
  color: #828282;
  padding: 0 0 0 27px;
  text-decoration: none;
}

.setting-menu ul li a.profile-btn.active-setting {
  border: 1px solid #219653;
  color: #219653;
}

.setting-menu ul li a.profile-btn.active-setting img {
  display: none;
}

.setting-menu ul li a.profile-btn.active-setting img.active-icon {
  display: unset;
}

.setting-menu ul li a img {
  margin: 0 13px 0 0;
}

.setting-menu ul li a img.active-icon {
  display: none;
}

.setting-menu ul li a.change-btn.change-active {
  border: 1px solid #219653;
  color: #219653;
}

.setting-menu ul li a.change-btn.change-active img {
  display: none;
}

.setting-menu ul li a.change-btn.change-active img.active-icon {
  display: unset;
}

.setting-menu .notification {
  width: 100%;
  display: flex;
  margin: 52px 0 0;
  align-items: center;
  justify-content: space-between;
}

.setting-menu .notification .notification-btn {
  width: auto;
  height: 28px;
}

.setting-menu .notification .notification-btn .switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.setting-menu .notification .notification-btn .switch input {
  display: none;
}

.setting-menu .notification .notification-btn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdbdbd;
  transition: 0.4s;
  border-radius: 30px;
}

.setting-menu .notification .notification-btn .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  border-radius: 50%;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.setting-menu .notification .notification-btn input:checked + .slider {
  background-color: #219653;
}

.setting-menu .notification .notification-btn input:checked + .slider:before {
  transform: translateX(26px);
  /* Move button to the right when on */
  background-color: white;
  /* Button color when on */
}

.setting-content {
  width: calc(100% - 300px);
  background-color: #fff;
  padding: 35px 37px 50px 38px;
  height: 100vh;
}

.setting-content h2 {
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 26px;
}

.setting-content .upload-profile {
  width: 100%;
  margin: 0 0 55px;
  position: relative;
}

.setting-content .upload-profile .upload-img {
  width: 200px;
  position: relative;
  height: 200px;
}

.setting-content .upload-profile .upload-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.setting-content .upload-profile .upload-img .edit-profile {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.setting-content .upload-profile .upload-img .edit-profile input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.setting-content .upload-profile .upload-img .edit-profile span {
  background-color: #56ccf2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setting-content .upload-profile .upload-img .edit-profile span img {
  width: auto;
  height: auto;
}

.setting-content .input-group {
  width: 100%;
  display: flex;
}

.setting-content .password-group {
  margin: 0 0 36px;
}

.setting-content .p-spacing {
  margin: 0 0 27px;
}

.setting-content .input-group .input-field {
  width: 33.33%;
  margin: 0 32px 0 0;
}

.setting-content .input-group .input-field:last-child {
  margin: 0;
}

.setting-content .input-group .input-field label {
  font-weight: 600;
  font-size: 16px;
  color: var(--font-color);
  display: block;
  margin: 0 0 9px;
}

.setting-content .input-group .input-field .password-input {
  width: 100%;
  position: relative;
}

.setting-content .input-group .input-field .password-input input {
  padding: 9px 22px 0;
}

.setting-content .input-group .input-field .password-input img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 11px;
}

.setting-content .input-group .input-field input {
  border: 1px solid #e0e0e0;
  height: 50px;
  padding: 0 22px;
  width: 100%;
}

.input-group_email .input-field {
  width: 100%;
  /* position: relative; */
  display: flex;
  flex-direction: column;
}

.input-group_name .input-field {
  width: 100%;
}

.input-group_name {
  display: flex;
  width: 100%;
}

.name_input input {
  border: 1px solid #e0e0e0;
  height: 50px;
  padding: 0 22px;
  width: 100%;
}

.name_input label {
  font-weight: 600;
  font-size: 16px;
  color: var(--font-color);
  display: block;
  margin: 0 0 9px;
}

.setting-content .input-group .input-field input::placeholder {
  font-weight: normal;
  font-size: 16px;
  color: var(--font-color);
}

.setting-content .setting-save-btn {
  width: 100%;
  margin: 43px 0 0;
}

.setting-content .setting-save-btn button {
  width: 156px;
  height: 50px;
  background-color: var(--font-color);
  color: #fff;
  font-weight: bold;
  border: none;
  font-size: 16px;
}

.change-password {
  display: none;
  width: 100%;
}

.change-password.change-active {
  display: inline-block;
}

.setting-profile-main.change-active {
  display: none;
}

.red-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  top: 20px;
  left: 22px;
}

.admin-photo {
  width: 200px;
  height: 200px;
}

.admin-photo .admin-upload {
  width: 200px;
  height: 200px;
  border: 1px dotted #e0e0e0;
  background-color: #fcfcfc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-photo .admin-upload .upload-icon {
  text-align: center;
}

.admin-photo .admin-upload .upload-icon i {
  display: block;
}

.admin-photo .admin-upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 11;
  cursor: pointer;
}

.password-input_setting,
.text-input_setting {
  width: 364px !important;
  height: 50px;
  border: 1px solid #e0e0e0;
}

.placeholder-name {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e0e0;
}

.placeholder-name_user {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e0e0;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  border: 0px !important;
}

.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__tile--active {
  background: #219653 !important;
  color: white !important;
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile--now {
  border-radius: 50% !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #000;
}

.react-calendar__tile {
  padding: 18px 6.67px !important;
}

/* Add these classes to your CSS */
.red-text {
  color: red !important;
}

.green-text {
  color: #219653 !important;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

/* .status-line {
  border-left: 2px solid blue;
} */
.admin-meeting-info.red {
  border-left: 3px solid #eb5757;
  border: 1 px solid #f2f2f2;
}

.admin-meeting-info.yellow {
  border-left: 3px solid #f2c94c;
  border: 1 px solid #f2f2f2;
}

.admin-meeting-info.green {
  border-left: 3px solid #219653;
  border: 1 px solid #f2f2f2;
}

.status-dot.yellow {
  background-color: #f2c94c;
}

.status-dot.green {
  background-color: #219653;
}

.status-dot.red {
  background-color: red;
}

/* PLOT MANAGEMENT CSS */

.product-type {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 15px 0;
}
.product-type span {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.product-type .plot-check {
  margin: 0 0 0 15px;
  display: flex;
  align-items: center;
  gap: 9px;
}
.product-type .plot-check .two {
  accent-color: yellow;
}
.product-type .plot-check .sold {
  accent-color: red;
}
.location_list-table tbody tr .no_data-image img {
  width: 30%;
  height: 30%;
  padding: 20px;
}
.no_data-image-one img {
  width: 30%;
  height: 30%;
  padding: 20px;
}
/* .no_data-image img {
  width: 100%;
  height: 100%;
} */
/* .loaction-list-main .plot-selection-box .plot-check .two:checked {
  font-family: "font-awesome";
  color: #fff;
} */
.product-type .plot-check .three {
  accent-color: #20519b;
}
.product-type .plot-check .four {
  accent-color: orange;
}
.product-type .plot-check .five {
  accent-color: #b6424c;
}
.product-type .product-field label {
  color: #4f4f4f;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}
.product-type .product-field input {
  width: 18px;
  height: 18px;
  accent-color: #219653;
}

.merge_button_disabled {
  background-color: gray;
  color: #fff;
  cursor: not-allowed;
  border: none;
  font-size: 14px;
  font-weight: bold;
  width: 115px;
  height: 100%;
}
.product-type .product-field input {
  width: 18px;
  height: 18px;
  margin: 0 5px;
  margin-top: 5px;
  accent-color: #219653;
}
.product-type .product-field .input-box {
  width: 18px;
  height: 18px;
  accent-color: #f61018;
}

.product-type .product-field:nth-child(3) input {
  accent-color: yellow;
}

.product-type .product-field:nth-child(4) input {
  accent-color: #20519b;
}

.product-type .product-field:nth-child(5) input {
  accent-color: orange;
}

.product-type .product-field:nth-child(6) input {
  accent-color: #b6424c;
}

.product-type .product-field label {
  font-size: 18px;
  margin: 0 5px;
  color: #4f4f4f;
  font-weight: 500;
}
.tab_content {
  /* display: contents !important; */
  background: transparent !important;
  color: #000000 !important;
  width: 100% !important;
}
.nav-link {
  display: block !important;
  width: 100% !important;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;
  font-size: var(--bs-nav-link-font-size) !important;
  font-weight: var(--bs-nav-link-font-weight) !important;
  color: var(--bs-nav-link-color) !important;
  text-decoration: none !important;
  background: none !important;
  border: 0 !important;
  color: #000 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out !important;
  margin-top: 10px !important;
}
.nav-link.active {
  background: none !important;
  color: #219653 !important;
  font-weight: bold !important;
  border-bottom: 5px solid #219653 !important;
  margin-top: 0px !important;
}
.nav_item.active {
  border: none !important;
}
.break_plot {
  background: red;
  color: #ffffff;
  padding: 5px;
  width: 100px;
  border: none;
  font-size: 14px;
  font-weight: bold;
}
.sticky {
  position: sticky;
  top: 0;
}
.quill {
  height: 100% !important;
  border: 1px dashed #219653 !important;
  font-size: 14px !important;
}
.ql-container.ql-snow {
  border: none !important;
  height: 253px !important;
}
.ql-editor {
  font-size: 16px;
}
.buyer-button {
  height: 40px !important;
  border: 2px solid #219653 !important;
  color: #219653 !important;
  border-radius: 0px !important;
  width: 100px !important;
  display: flex !important;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buyer-details ul {
  display: flex;
  /* justify-content: space-between; */
}
.buyer-button-one {
  height: 40px !important;
  border: 1px solid #c0c0c0 !important;
  color: #c0c0c0 !important;
  border-radius: 0px !important;
  width: 100px !important;
  font-weight: 600;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buyer-info ul li {
  list-style-type: none;
  margin: 0 0 15px;
}
.nav-tabs li:nth-child(2) {
  margin-left: 25px;
}
.user-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bold;
  background: #c0c0c0;
}
.upload_loader {
  border: 5px solid #219653;
  border-top-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: rotate 1s linear infinite;
  margin: 30px 35px 0 35px;
}

.upload_loader-one {
  border: 5px solid #219653;
  border-top-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: rotate 1s linear infinite;
  /* margin: 35px; */
}
.edit_loader-one {
  border: 3px solid #219653;
  border-top-color: #ffffff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: rotate 1s linear infinite;
  position: absolute;
  left: 50%;
}
.edit_loader-two {
  border: 3px solid #219653;
  border-top-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: rotate 1s linear infinite;
  position: absolute;
  left: 50%;
}
.upload_loader-two {
  border: 4px solid #ffffff;
  border-top-color: #219653;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

.react-calendar__tile {
  padding: 12px !important;
  line-height: 13px;
}
.plot-management-tab {
  height: 67vh;
  overflow-y: auto;
}
.general-info-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .user-edit-button {
  position: relative;
} */
/* .ql-editor {
  height: 82% !important;
} */

.purchase-button:hover{
    background: transparent;
    border: 1px solid #219653;
    border-radius: 5px;
    color: #219653;
    padding: 4px 10px;
    font-weight: bold;
}

.purchase-button {
  /* background: transparent; */
  background: #219653;
  border-radius: 5px;
  color: #ffffff;
  padding: 4px 10px;
  font-weight: bold;
}

.buyers-main {
  width: 630px;
  background-color: #fff;
  padding: 25px 30px;
  position: relative;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  z-index: 1111;
}

.buyers-main .closeBtn {
  position: absolute;
  top: 26px;
  right: 36px;
  background: none;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  cursor: pointer;
  border: none;
}

.buyers-main h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  margin: 20px 0 31px;
}

.buyers-main .head {
  width: 100%;
  display: inline-block;
  margin: 0 0 15px;
}

.buyers-main .head p {
  color: #6c6c6c;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 14px;
}

.buyers-main .head p span {
  /* color: #219653; */
  display: inline-block;
  margin: 0 19px 0 0;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px 12px;
  font-size: 15px;
}

.buyers-main .head p .active {
  color: #219653;
  display: inline-block;
  margin: 0 19px 0 0;
  background-color: #bff0d4;
  border: 1px solid #7ad5a1;
}

.buyers-main .head p span b {
  color: #e60000;
  margin: 10px 0 0;
  font-size: 20px;
}

.buyers-main .input-group {
  width: 100%;
}

.buyers-main .input-group .input-field {
  width: 100%;
  margin: 0 0 14px;
}

.buyers-main .input-group .input-field label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 0 5px;
  display: block;
}

.buyers-main .input-group .input-field label span {
  display: inline-block;
  color: #e60000;
}

.buyers-main .input-group .input-field input,
.buyers-main .input-group .input-field textarea {
  width: 100%;
  /* height: 50px; */
  padding: 12px 10px;
  font-size: 16px;
  /* border: 1px solid #E0E0E0; */
}

.buyers-main .input-group .input-field textarea {
  padding: 16px 11px;
  font-family: "Inter", sans-serif;
}

/* .buyers-main .input-group .input-field textarea::placeholder {
    padding:16px 0 0 ;
} */
.buyers-main .input-group .input-field input::placeholder,
.buyers-main .input-group .input-field textarea::placeholder {
  color: #bdbdbd;
}

.buyers-main .input-group .input-field textarea {
  height: 92px;
}

.buyers-main .input-group .input-pin {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.buyers-main .input-group .input-pin .input-code {
  width: 48%;
}

.buyers-main .next-btn {
  width: 100%;
  height: 50px;
  background-color: #219653;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  display: unset;
  cursor: pointer;
  margin-top: 19px;
}

.cart_main{
    width: 340px;
    background-color: #fff;
    text-align: center;
    padding: 18px 26px 38px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
 
.cart_main {
    font-weight: bold;
    font-size: 18px;
    margin: 12px 0 0;
    display: block;
    
}
 
 
 
.cart_main ul li button {
    width: 100% !important;
}
.cart_main ul {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.cart_main ul li {
    width: 50%;
}
 
.cart_main ul li button {
    width: 80px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #828282;
    font-size: 14px;
    font-weight: 600;
    background-color: #E0E0E0;
    margin: 0 0 0 10px;
}
 
/* .cart_main ul li a.active{
    background-color: #219653;
    color: #fff;
} */
 
 
.cart_main p {
    font-size: 14px;
    line-height: 18px;
    margin: 12px 0 30px;
}

.buyer-popup_button{
  display:flex;
  justify-content:center;
  align-items:center;
  gap: 10px;
}
.buyer-popup_button .no-btn{
  background: transparent;
    border: 1px solid #219653;
    border-radius: 5px;
    color: #219653;
    padding: 4px 10px;
    font-weight: bold;
}

.buyer-popup_button .yes-btn{
    background: #219653;
    border: 1px solid #219653;
    border-radius: 5px;
    color: #ffffff;
    padding: 4px 10px;
    font-weight: bold;
}
.buyer_close-popup{
  border: none;
  color: white;
  font-weight: 600;
  background: #219653;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.button-div{
  display:flex;
  justify-content: end;
  align-items: center;
}
/* .input-group-button-one{
  width: 50%;
} */
.input-group-button-two{
  width: 100%;
}
.input-group-button-three{
  width: 100%;
}
.input-group-button-three label span{
  font-size:14px;
  color: #219653;
}
.category-slug{
  display: flex;
  gap: 15px;
}
.category-slug .input-group-button{
  width: 50%;
}
.meta-content-description{
  display: flex;
  gap: 15px;
  margin:0 0 15px;
}

.meta-content-description  button{
    background: transparent;
      border: 1px solid #219653;
      color: #219653;
      padding: 4px 10px;
      font-weight: bold;
      width: 100%;
      margin-top: 33px;
      height: 50px;
}
.meta-content-description .first-div{
  width: 25%;
}
.meta-content-description .second-div {
  width: 56%;
}
.meta-content-description .third-div {
  width: 8%;
  display:flex;
  gap:10px;
}
.meta-content-description .third-div button {
  white-space:nowrap;
}

.custom-input input {
  width:100%;
  height:45px;
  border:1px solid #E0E0E0;
  padding:0 10px;
}

.category-slug  .input-group-button label span {
  font-size:12px;
  color:#219653;
}