@keyframes ldio-1lojyfnlp42 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio div {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 6px solid #219653;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio div {
  animation: ldio-1lojyfnlp42 0.9523809523809523s linear infinite;
  top: 50%;
  left: 50%;
}
.loadingioSpinnerRolling {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);
}
.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio div {
  box-sizing: content-box;
}


@keyframes ldio-1lojyfnlp42 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.spinner div {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 6px solid ;
  border-top-color: transparent;
  border-radius: 50%;
}
.spinner div {
  animation: ldio-1lojyfnlp42 0.9523809523809523s linear infinite;
  top: 50%;
  left: 50%;
}
.spinnerRolling {
  width: 100%;
    height: 30px;
    display: flex;
    overflow: hidden;
    background: rgba(255, 255, 255, 0);
    justify-content: center;
    align-items: center;
}
.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.6);
  backface-visibility: hidden;
/*  transform-origin: 0 0;  see note above */
}
.spinner div {
  box-sizing: content-box;
}
