/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px !important;
  height: 254px !important;
  z-index: 1100;
}

/* Modal Content */
.modal-contentmodal-content {
  background-color: white;
  border-radius: 0;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Modal Icon */
.modal-icon {
  font-size: 24px;
  color: #fff;
  background: #219653;
  border-radius: 50%;
  padding: 4px;
  width: 35px;
  height: 35px;
}

/* Modal Close Button */
.modal-close-btn {
  background-color: #e0e0e0;
  color: #828282;
  border: none;
  border-radius: 0;
  width: 80px;
  height: 33px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.modal-confirm-btn-one {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-confirm-btn {
  background-color: #219653;
  color: #fff;
  border: none;
  border-radius: 0;
  min-width: 80px;
  height: 33px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  transition: background-color 0.3s;
}

/* Modal Text */
.modal-body h2 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}
.modal-body {
  margin-top: 10px;
}

.modal-body p {
  margin-bottom: 10px;
  color: #828282;
  line-height: 20px;
}
.no-scroll {
  overflow: hidden !important;
}
.user-no-button {
  margin-top: 0;
}
.user-edit-button {
  position: relative;
}
.modal-footer {
  display: flex;
  justify-content: center !important;
  align-items: center;
}
