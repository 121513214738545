/***************** login/forgotpassword/verifyotp css start **************/

/* .homepage_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
} */
input[type="email"] {
  width: 100%;
  min-height: 70px;
  border: 1px solid #e0e0e0;
  padding: 0 63px;
  font-size: 18px;
  border-radius: 0px;
}
.password-input,
.text-input {
  width: 100%;
  min-height: 70px;
  border: 1px solid #e0e0e0;
  padding: 0 63px !important;
  font-size: 18px;
}
.title-wrapper {
  text-align: left !important;
}

.homepage_left p {
  font-size: 16px;
  color: var(--font-color);
  margin: 5px 0 30px 0;
}
.homeimg img {
  width: 100% !important;
  object-fit: cover;
  height: 100vh;
}
.login-input-details {
  width: 500px;
}
.input-group {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 16px 0;
}
.input-group input::placeholder {
  font-size: 16px;
  color: #bdbdbd;
}
.input-group i {
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  color: #bdbdbd !important;
  font-size: 22px;
}
.form-check-input:checked {
  background-color: #219653 !important;
  border-color: #219653 !important;
}
.input-group .password-hide i {
  right: 15px;
  left: inherit;
  color: var(--font-color);
  background-size: cover;
}
.input-group > .form-control:focus {
  z-index: 0 !important;
}
.login-btn {
  width: 100%;
  background-color: var(--btn-color) !important;
  border: 0px !important;
  border-radius: 0px !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center;
  display: flex;
  min-height: inherit;
  height: 65px;
}
.forgot-password {
  color: #828282;
  text-decoration: underline;
  font-size: 16px;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border: 1px solid #828282;
}
.form-check label {
  color: var(--font-color);
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
}
.back_login-page {
  color: #828282;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
}
.reset_link {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
}
.otp-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 19px 0;
}
.otp-input input {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #e0e0e0;
  outline: none;
}
.resend-otp p {
  color: var(--font-color);
  font-weight: 500;
}
.resend-otp p .resend_link {
  color: var(--btn-color);
  font-weight: 500;
  text-decoration: none;
}
/***************** login/forgotpassword/verifyotp css end **************/
.loading {
  position: relative;
  opacity: 0.7;
  z-index: 1;
}
/* Validation style for red border */
/* .invalid-input {
    border: 1px solid red !important;
  } */

/* Style for displaying the error message */
.error-message {
  color: red;
  margin-top: 5px;
}
